// pollyfills
import 'core-js/modules/es6.string.starts-with';
import 'core-js/modules/es6.object.assign';
import 'es6-promise/auto';
import 'whatwg-fetch';

import * as React                  from 'react';
import * as ReactDOM               from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App                         from './app';

// @ path is not working in vscode yet :(
import { startSegment }            from '../../../common/ui/segment';

interface GlobalScope extends Window {
    Typekit: {
        load: (a: object) => void;
    };
}

const global = window as unknown as GlobalScope;

// Start webfonts
global.hasOwnProperty('Typekit') && global.Typekit.load({ async: true });

// Start segment(fullstory and possibly intercom)
process.env.SEGMENT_KEY && startSegment(process.env.SEGMENT_KEY);

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('main')
);
