import {
    compose,
    branch,
    renderComponent,
    lifecycle,
    withProps,
} from "recompose";
import { withRouter } from "react-router";
import socketContainer from "./SocketContainer";
import calleeContainer from "./CalleeContainer";
import LoadingAnimation from "@portlet/components/LoadingAnimation/LoadingAnimation";
import translationsContianer from "@portlet/translation/TranslationsContainer";
import { connectSharedState } from "@portlet/sharedState";
import { SharedFormProgress } from "@portlet/types/SharedTypes";
import sessionRefreshEnhancer from "@portlet/containers/SessionRefreshContainer";
import { AppProps } from "@portlet/types/CallTypes";
import { detectMobile } from "../helpers/mobile";

export default compose(
    withRouter,
    calleeContainer,
    withProps((ownProps: AppProps) => ({
        userLanguage: ownProps.calleeConfig.language,
    })),
    socketContainer,
    translationsContianer,
    connectSharedState,
    sessionRefreshEnhancer,
    lifecycle({
        // redirect back to the search component after a call has been successfully logged
        componentDidUpdate(prevProps: AppProps) {
            if (
                this.props.sharedState.form.logActivity.status ===
                    SharedFormProgress.SAVED &&
                this.props.history.location.pathname !== "/calling"
            ) {
                const isMobile = detectMobile();
                console.info("isMobile", isMobile); // keeping this for testing in dev only

                if (isMobile) {
                    setTimeout(() => {
                        console.info("close window"); // keeping this for testing in dev only
                        window.close();
                    }, 4000);
                }
            }

            if (
                prevProps.sharedState.form.logActivity.status !==
                    this.props.sharedState.form.logActivity.status &&
                this.props.sharedState.form.logActivity.status ===
                    SharedFormProgress.SAVED
            ) {
                this.props.history.push("/calling");
            }
        },
    }),
    branch(
        ({ translations }) => !translations.loaded,
        renderComponent(LoadingAnimation)
    )
);
