import SocketService from '../services/SocketService';
import { getToken }      from '@portlet/services/SessionService';
import {
    SocketDispatchers,
    SocketActionTypes,
    SocketExchanges,
    SocketResponseBody,
    SocketStatuses
}                        from '@portlet/types/SocketTypes';
import { CallStatus }    from '@portlet/types/CallTypes';

export default function createSocketDispatchers(props): SocketDispatchers {
    const { socketDispatch: dispatch } = props;

    return {
        async initializeCallSocket() {
            if (!props.socketState.socket) {
                try {
                    const token = await getToken();
                    const socket = new SocketService(`${STFLO_ENV_VARS.RELAY_HOST}/sync`, { api_key: token });
                    await socket.connect();

                    dispatch({ type: SocketActionTypes.SOCKET_INITIALIZE, socket });
                } catch (error) {
                    dispatch({ type: SocketActionTypes.SOCKET_ERROR, error: { message: error.headers && error.headers.message || error } });
                }
            }
        },
        async disconnectCallSocket() {
            const { socket } = props.socketState;

            if (socket && socket.connected) {
                try {
                    await socket.disconnect();
                } catch (error) {
                    dispatch({ type: SocketActionTypes.SOCKET_ERROR, error: { message: error.headers && error.headers.message || error } });
                }
            }
        },
        startCall() {
            props.socketState.socket.message(SocketExchanges.CALL_INITIATE, JSON.stringify({
                destination_phone_number: props.calleeConfig.to,
                account_id: props.calleeConfig.banId ? props.calleeConfig.banId : null,
                contact_id: props.calleeConfig.contactId ? props.calleeConfig.contactId : null,
            }));
        },
        endCall() {
            if (props.socketState.callStatus && props.socketState.callStatus === CallStatus.RINGING) {
                props.socketState.socket.message(SocketExchanges.CALL_ABORT, JSON.stringify({
                    call_id: props.socketState.callId
                }));
            }
        },
        resetCall() {
            dispatch({ type: SocketActionTypes.RESET_STATUS });
        },
        onCallSocketUpdate(payload: SocketResponseBody) {
            switch (payload.details.status) {
                case SocketStatuses.INITIATE:
                    dispatch({
                        type: SocketActionTypes.START_CALL,
                        callId: payload.details.call.id
                    });
                    break;

                case SocketStatuses.INITIATED:
                    dispatch({
                        type: SocketActionTypes.CALL_INITIATED,
                        referenceId: payload.details.metadata.reference_id
                    });
                    break;

                case SocketStatuses.ORIGIN_NOT_AUTHORIZED:
                    dispatch({
                        type: SocketActionTypes.NOT_AUTHORIZED,
                        authUrl: payload.details.metadata.authorization_url
                    });
                    break;

                case SocketStatuses.ORIGIN_RINGING:
                    dispatch({ type: SocketActionTypes.CALL_STARTED });
                    break;

                // for RC -> show LAA form and timer
                // for Twilio -> remove abort call button
                case SocketStatuses.ORIGIN_CONNECTED:
                    const type = (payload.details.display_status === SocketStatuses.IN_PROGRESS) ? SocketActionTypes.CALL_ANSWERED : SocketActionTypes.ORIGIN_CONNECTED;
                    dispatch({ type });
                    break;

                // we only receive this event for Twilio
                // for Twilio -> show LAA form and timer
                case SocketStatuses.DESTINATION_CONNECTED:
                    dispatch({ type: SocketActionTypes.CALL_ANSWERED });
                    break;

                case SocketStatuses.DESTINATION_NOT_ANSWERED:
                    dispatch({ type: SocketActionTypes.CALL_NOT_ANSWERED });
                    break;

                case SocketStatuses.ABORTED:
                case SocketStatuses.ORIGIN_NOT_ANSWERED:
                    dispatch({ type: SocketActionTypes.CALL_ABORTED });
                    break;

                case SocketStatuses.DESTINATION_FINISHED:
                case SocketStatuses.ORIGIN_FINISHED:
                    dispatch({ type: SocketActionTypes.END_CALL });
                    break;

                case SocketStatuses.FAILED:
                    dispatch({
                        type: SocketActionTypes.CALL_ERROR,
                        error: {
                            message: payload.details.message
                        }
                    });
                    break;

                default:
                    break;
            }
        }
    };
}
