import { fetchTranslations } from '../services/TranslationService';
import { TranslationsDispatchers, TranslationsActionTypes } from '../types/TranslationTypes';

export function createTranslationDispatchers(props): TranslationsDispatchers {
    const { translationsDispatch: dispatch } = props;

    return {
        async loadTranslations(language: string) {
            try {
                await fetchTranslations(language);

                dispatch({ type: TranslationsActionTypes.LOADED });
            } catch (error) {
                dispatch({ type: TranslationsActionTypes.ERROR, error });
            }
        }
    };
}
