import { CalleeConfig } from '@portlet/types/CallTypes';
import { CalleeActionPayload, CalleeActionTypes } from '@portlet/types/CalleeTypes';

export const defaultState: CalleeConfig = {
    from: null,
    language: null,
    relay: null,
    unrecognized: null
};

export function calleeReducer(state: CalleeConfig, action: CalleeActionPayload): CalleeConfig {
    if (process.env.NODE_ENV === 'development') {
        console.group(`Callee action: ${CalleeActionTypes[action.type]}`);
        console.log(action);
        console.groupEnd();
    }

    switch (action.type) {
        case CalleeActionTypes.LOADED:
            return {
                ...state,
                from: action.data.from,
                language: action.data.language,
                relay: action.data.relay,
                unrecognized: action.data.unrecognizedEnabled,
                name: action.data.contactName ? action.data.contactName : action.data.clientName, // fallback to clientName if not contactName provided
                contactId: action.data.contactId,
                outlet: action.data.outletId,
                banId: action.data.banId,
                to: action.data.to,
                dnc: action.data.dnc
            };

        case CalleeActionTypes.ERROR:
            return {
                ...state,
                error: action.error
            };

        case CalleeActionTypes.RESET:
            return {
                ...state,
                name: null,
                to: null,
                banId: null,
                outlet: null,
                contactId: null
            };

        case CalleeActionTypes.UPDATE_BAN_ID:
            return {
                ...state,
                banId: action.banId
            };

        default:
            return state;
    }
}
