import { lifecycle } from 'recompose';
import { getToken }  from '../services/SessionService';

const SESSION_VALIDATION_TIMEOUT = 840000; // 14 min in ms to ensure previous token/session still valid

const refreshSession = async () => await getToken();

const sessionRefreshEnhancer = lifecycle({
    componentDidMount() {
        this.sessionTimer = setInterval(refreshSession, SESSION_VALIDATION_TIMEOUT);
    },
    componentWillUnmount() {
        clearInterval(this.sessionTimer);
    }
});

export default sessionRefreshEnhancer;
