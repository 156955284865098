import { RouteComponentProps } from 'react-router';
import * as PropTypes    from 'prop-types';
import { SharedStateContextTypes, SharedStateDispatchers, SharedState, SharedStateContext } from './SharedTypes';
import { ActionPayload } from '.';
import { FormKey, FormContext } from './FormTypes';
import { CalleeContainerProps } from './CalleeTypes';
import { SocketContainerProps } from './SocketTypes';
import { TranslationsContainerProps } from './TranslationTypes';

export interface AppProps extends RouteComponentProps<CallRouteParams>, SharedStateContext, CalleeContainerProps, SocketContainerProps, TranslationsContainerProps {}

export type EmptyFunc = () => void;

export enum CallStatus {
    PENDING,
    CONNECTING     = 'connecting',
    INITIATED      = 'initiated',
    RINGING        = 'ringing',
    CONNECTED      = 'connected',
    ANSWERED       = 'answered',
    NOT_ANSWERED   = 'unreachable',
    COMPLETED      = 'done',
    ERROR          = 'error',
    UNTRACEABLE    = 'untraceable',
    DISCONNECTED   = 'disconnected',
    NOT_AUTHORIZED = 'notAuthorized'
}

export type CallStatusType = CallStatus;

export enum CallFormActionTypes {
    FINISH_CALL,
    SAVE_CALL,
    CALL_ERROR,
    UPDATE_DEFAULT_VALUES,
    CHANGE_FORM,
    UPDATE_CONTACT,
    REMOVE_CONTACT
}

export enum CallTargetTypes {
    RING_OUT = 'ringout',
    BROWSER  = 'in-browser'
}

export const CallContextTypes = {
    startCall: PropTypes.func,
    endCall: PropTypes.func,
    saveCall: PropTypes.func,
    updateDefaultFormState: PropTypes.func,
    updateFormStep: PropTypes.func,
    updateSelectedContact: PropTypes.func,
    removeSelectedContact: PropTypes.func,
    addLead: PropTypes.func,
    addContact: PropTypes.func,
    resetCallStatus: PropTypes.func,
    translate: PropTypes.func,
    callStatus: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    formStep: PropTypes.string,
    formContext: PropTypes.string,
    connected: PropTypes.bool,
    selectedContact: PropTypes.object,
    error: PropTypes.object,
    ...SharedStateContextTypes
}

export enum CallFormNavigation {
    NOTES,
    CONTACT,
    LOG_ACTIVITY
}

export type CallId = number;

export interface ErrorPayload {
    message: string
}

export type CallFormActionPayload = ActionPayload<CallFormActionTypes>;

export interface DefaultFormPayload {
    [formKey: string]: string;
}
export interface CallFormDispatchers {
    saveCall?:                 (formValues) => void;
    updateDefaultFormState?:   (payload: DefaultFormPayload) => void;
    updateFormStep?:           (formKey: FormKey) => void;
    updateSelectedContact?:    (contact: CallFormContact) => void;
    removeSelectedContact?:    EmptyFunc;
    addLead?:                  (formValues) => void;
    addContact?:               (formValues) => void;
};

export interface CallFormContact {
    banId: string;
    name: string;
    dealerId: string;
}

export interface CallFormUIState {
    defaultFormValues: DefaultFormPayload;
    formStep:          FormKey;
    selectedContact?:  CallFormContact;
};

export interface UserSession {
    id: string;
    sid?: string;
    dealer_id: string;
    firstname: string;
    token?: string;
}

export interface CalleeConfig {
    from: string;
    name?: string;
    outlet?: string;
    language?: string;
    banId?: string;
    to?: string;
    relay?: string; // RC specific prop
    unrecognized: boolean;
    error?: ErrorPayload;
    contactId?: string;
    dnc?: boolean;
}

export interface CallProps extends AppProps {
    session?:                UserSession;
    formContext:             FormContext;
    host?:                   string;
    menuConfigshowDialer?:   boolean;
    menuConfig?:             CallFormUIState;
    dispatchers?:            CallFormDispatchers;
    callFormState:           CallFormUIState,
    sharedStateDispatchers?: SharedStateDispatchers;
    sharedState?:            SharedState;
    callFormDispatch:        (action: CallFormActionPayload) => void
}

export interface CallButtonContext extends SharedStateContext, CallFormDispatchers, CallFormUIState {
    translate?: TranslationFunction;
    formContext: FormContext;
}

export interface CallFormProps {
    banId?: string;
    defaultValues: DefaultFormPayload;
    formContext: FormContext;
    formStep: string;
    session: UserSession;
    status: CallStatus;
    updateBanId: (banId: string) => void;
}

export interface CallRouteParams {
    to?: string;
    banId?: string;
}

