
import { getToken } from '@portlet/services/SessionService';

export interface CalleePayload {
    language?: string;
    provider: string;
    relay?: string;
    from: string;
    unrecognizedEnabled: boolean;
    clientName: string;
    contactName?: string;
    contactId?: string;
    dnc?: boolean;
}

interface CalleeRouteConfig {
    banId?: string;
    to?: string;
}

export async function fetchCalleeConfig(routeConfig?: CalleeRouteConfig): Promise<CalleePayload> {
    const formattedLink = `${STFLO_ENV_VARS.WEBAPP_HOST}/v1/calling/popout/caller-info` + [
        routeConfig.banId ? `/${routeConfig.banId}` : '',
        routeConfig.to ? `/${routeConfig.to}` : ''
    ].join('');

    const token = await getToken();
    const banId = routeConfig.banId ? routeConfig.banId : '';
    const phoneNumber = routeConfig.to ? routeConfig.to : '';
    const dncApiLink = `${STFLO_ENV_VARS.WEBAPP_HOST}/v2/api/cpm/${phoneNumber}?ban_id=${banId}`;
    let dnc = false;
    if (banId) {
        const dncResponse = await fetch(dncApiLink, ({
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            mode: 'cors',
            cache: 'no-cache'
        }));
        const dncResult = await dncResponse.json();
        if (dncResult[banId]) {
            dnc = true;
        }
    }

    const response = await fetch(formattedLink, ({
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache'
    }));

    if (!response.ok) {
        throw {
            message: `Error ${response.status} - ${response.statusText}`
        };
    }

    const config = await response.json();
    config.dnc = dnc;
    return config;

}
