import { getToken }       from './SessionService';
import {
    FormResponse,
    ContactFormResponse
}                         from '../types/FormTypes';
import { SharedFormData } from '../types/SharedTypes';

// no parameters, so only need to fetch the form data once
let contactFormCache = null;

export async function loadAddContactForm(): Promise<FormResponse> {
    if (contactFormCache) {
        return contactFormCache.fields;
    }

    const token = await getToken();

    const response = await fetch(`${STFLO_ENV_VARS.WEBAPP_HOST}/v1/api/unrecognized/add-contact/form`, ({
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache'
    }));

    if (!response.ok) {
        throw {
            message: [`Error ${response.status} - ${response.statusText}`]
        };
    }

    const contactForm = await response.json();

    contactFormCache = contactForm;

    return contactForm.fields;
}

export async function submitAddContactForm(formData: SharedFormData): Promise<ContactFormResponse> {
    const { method, url } = contactFormCache;

    const token = await getToken();

    const response = await fetch(url, ({
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData)
    }));

    const payload = await response.json();

    // error handling
    if (!response.ok) {
        throw {
            message: payload
        }
    }

    return payload;
}

