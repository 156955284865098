export interface ExpirableCache {
    [key: string]: CacheEntry;
}

interface CacheEntry {
    key: string;
    value: any;
    ttl?: number;
}

class ExpiringCache {
    cache: ExpirableCache;

    constructor() {
        this.cache = Object.create(null);
    }

    put(entry: CacheEntry) {
        const { key, value, ttl: expires } = entry;

        const record = {
            value,
            key,
            ttl: expires && expires + new Date().getTime()
        };

        this.cache[key] = record;
    }

    get(key: string) {
        const record = this.cache[key];

        // check if record expired, delete it if so
        if (record && record.ttl && record.ttl < new Date().getTime()) {
            this.delete(key);
            return null;
        }

        // otherwise return the record if still valid
        return record || null;
    }

    delete(key: string) {
        const { [key]: oldValue, ...newValues } = this.cache;
        this.cache = newValues;
    }
}

export default ExpiringCache;
