import { getToken }              from './SessionService';
import { translationsCacheMock } from '@portlet/services/__mocks__/TranslationService';

interface TranslationResponse {
    [translationKey: string]: string;
}

export let translationsCache = null;
let token = null;

// keys for UI translations
const keys = [
    'account',
    'add_and_invite',
    'add_contact_message',
    'add_contact',
    'add_opportunity',
    'add_user_warning_header',
    'add_user_warning',
    'add_user',
    'all_reps',
    'all',
    'answer_no',
    'answer_yes',
    'apply',
    'are_you_sure_you_want_to_remove',
    'bus_ex_flag',
    'business_excellence_accounts',
    'call_logged_successfully',
    'call_me',
    'call_navigate_warning',
    'calling_subscribers',
    'cancel',
    'change_number_settings',
    'change_number',
    'close_call_confirm',
    'confirm',
    'connecting',
    'cpms_deactivated_users_header',
    'cpms_deactivated_users_message',
    'cpms_deactivated_users_search_placeholder',
    'cpms_deactivated_users_submit_success_msg',
    'cpms_delete_user_error_msg',
    'cpms_delete_user_success_msg',
    'cpms_new_users_confirm_ignore_header',
    'cpms_new_users_confirm_ignore_msg',
    'cpms_new_users_header',
    'cpms_new_users_ignore',
    'cpms_new_users_message',
    'cpms_new_users_search_placeholder',
    'cpms_new_users_submit_error_msg',
    'cpms_new_users_submit_success_msg',
    'cpms_statflo_in_sync',
    'cpms_validated',
    'create_lead',
    'create_non_carrier_user',
    'deactivated',
    'deactivation_date',
    'done',
    'edit_user',
    'edit_users_managers',
    'end_call',
    'end_this_call',
    'enter_number',
    'filter_by',
    'hang_up_message',
    'hang_up_rc',
    'having_issues_call',
    'last_updated',
    'lead',
    'loading',
    'name',
    'new_call',
    'new',
    'next',
    'no_contacts',
    'no_email_provided',
    'no_matches',
    'no_records_available',
    'no_search_results',
    'now_calling',
    'other_accounts',
    'outlet_id',
    'pickup_and_follow',
    'please_confirm_your_changes',
    'reason_for_change',
    'refine_search',
    'remove',
    'rep_status',
    'reset_call',
    'reset_form',
    'reset',
    'retry',
    'sales_person_id',
    'sales_rep_id',
    'save_and_invite',
    'save_changes',
    'save_information_message',
    'save',
    'save',
    'saving',
    'search_here',
    'select',
    'settings',
    'sorry_we_could_not_find_any_accounts',
    'start_call',
    'start_date',
    'statflo_role',
    'stop_ringing_message',
    'subscriber',
    'telus_job_title',
    'then_connect_you',
    'timer_placeholder',
    'unexpected_end_message',
    'unrecognized_permission_message',
    'users_list_count_total',
    'view_accounts',
    'we_will_call_you',
    'will_ring',
    'your_phone',
    'this_customer_has_requested_not_to_be_contacted',
    'back'
];

/**
 * Creates headers needed for accesssing translations API
 * Checks whether browser supports Fetch API Headers interface
 *
 * @param {string} authToken - valid JWT
 * @return {(Headers | Object)} browser specific authorization headers
 */
function createSessionInstance(authToken: string) {
    const sessionHeaders = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${authToken}`,
        'Content-type': 'application/json',
    };

    // unfetch polyfill compatibility
    return window.hasOwnProperty('Headers') ? new Headers(sessionHeaders) : sessionHeaders;
}

/**
 * Consumes the translations API
 * Caches the response for future use by the translate function
 *
 * @param {string=} lang - User's locale
 * @param {string[]=} additionalKeys - Additional keys to be translated
 * @return {TranslationResponse} translated values in the keys array
 */
export async function fetchTranslations(lang: string = 'en', additionalKeys: string[] = []): Promise<TranslationResponse> {
    // retrieve authorization token if it doesn't exist already
    if (token === null) {
        token = await getToken();
    }

    const keysToFetch = additionalKeys.concat(keys);
    const response = await fetch(
        `${STFLO_ENV_VARS.TRANSLATION_HOST}/v1/common/translation/${lang}`,
        ({
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: createSessionInstance(token),
            body: JSON.stringify({keys: keysToFetch}),
        })
    );

    if (!response.ok) {
        throw {error: `Error ${response.status} - ${response.statusText}`};
    }

    translationsCache = await response.json();

    return translationsCache;
}

/**
 * Consumes the translations API
 * Caches the response for future use by the translate function
 *
 * @param {string=} lang - User's locale
 * @param {string[]=} additionalKeys - Additional keys to be translated
 * @return {TranslationResponse} translated values in the keys array
 */
export async function fetchGlobalTranslations(lang: string = 'en'): Promise<TranslationResponse> {
    // retrieve authorization token if it doesn't exist already
    if (token === null) {
        token = await getToken();
    }

    const response = await fetch(
        `${STFLO_ENV_VARS.TRANSLATION_HOST}/v1/common/translation?language=${lang}`,
        ({
            method: 'GET',
            mode: 'cors',
            headers: createSessionInstance(token),
            cache: 'no-cache'
        })
    );

    if (!response.ok) {
        throw {error: `Error ${response.status} - ${response.statusText}`};
    }

    translationsCache = await response.json();

    return translationsCache;
}

/**
 * Used in the UI to translate keys as needed
 *
 * @param {string} key - key to be translated
 * @param {string[]=} data - ordered replacements for any translation params (specified by {})
 * @return {string} translation for the specified key, read from translationsCache
 */
export function translate(key: string, data?: string[]): string {
    let translated: string = translationsCache[key] || key;

    if (!data || data.length === 0) return translated;

    // get all text that are enclosed in {} and replace value from data by index.
    const dynamicDataKeys = translated.match(/\{.*?\}/g);
    const len             = dynamicDataKeys ? dynamicDataKeys.length : 0;

    if (len === 0) return translated;

    data.map((item, index) => {
        if (index >= len) return; // capture overflow

        translated = translated.replace(dynamicDataKeys[index], item);
    });

    return translated;
}

/**
 * Set `translationCache` with mock data for tests
 */
export const fetchTranslationCacheWithMock = () => {
    translationsCache = translationsCacheMock;
};

