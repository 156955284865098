import * as React                     from 'react';
import {
    Link,
    Prompt
}                                     from 'react-router-dom';
import icons                          from '../../icons';
import {
    SharedFormProgressType,
    SharedFormProgress
}                                     from '@portlet/types/SharedTypes';
import { CallStatusType, CallStatus } from '@portlet/types/CallTypes';

interface HeaderComponentProps {
    callStatus: CallStatusType;
    translate: TranslationFunction;
    formState: SharedFormProgressType
}

const callInProgress = (status: CallStatusType, formState: SharedFormProgressType) => status !== CallStatus.PENDING && formState !== SharedFormProgress.SAVED;

const shouldDisplayNewCall = status => (Boolean(
    status    === CallStatus.PENDING
    || status === CallStatus.ERROR
    || status === CallStatus.COMPLETED
    || status === CallStatus.DISCONNECTED
    || status === CallStatus.NOT_ANSWERED
));

const HeaderComponent = ({ callStatus, translate, formState }: HeaderComponentProps) => (
    <div className="stfloCallWidget__callHeader">
        {icons.STATFLO_LOGO}
        <Link to="/calling">
            <Prompt when={callInProgress(callStatus, formState)} message={location => translate('call_navigate_warning')} />
            <button
                className="stfloCallWidget__button stfloCallWidget__button--newCall"
                disabled={!shouldDisplayNewCall(callStatus)}
            >
                {translate('new_call')}
            </button>
        </Link>
    </div>
);

export default HeaderComponent;
