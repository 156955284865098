import { TranslationsState, TranslationsActionTypes, TranslationsActionPayload } from '../types/TranslationTypes';

export const defaultState: TranslationsState = {
    loaded: false
};

export function translationsReducer(state: TranslationsState, action: TranslationsActionPayload): TranslationsState {
    if (process.env.NODE_ENV === 'development') {
        console.group(`Translations action: ${TranslationsActionTypes[action.type]}`);
        console.log(action);
        console.groupEnd();
    }

    switch (action.type) {
        case TranslationsActionTypes.LOADED:
            return {
                ...state,
                loaded: true
            };

        case TranslationsActionTypes.ERROR:
            return {
                ...state,
                loaded: false,
                error: action.error
            };

        default:
            return state;
    }
}
