import { getRouteParams }    from '../helpers/getRoute';
import { fetchCalleeConfig } from '../services/CalleeService';
import {
    CalleeContainerProps,
    CalleeDispatchers,
    CalleeActionTypes
}                            from '@portlet/types/CalleeTypes';

export function createCalleeDispatchers(ownProps: CalleeContainerProps): CalleeDispatchers {
    const { calleeDispatch: dispatch } = ownProps;

    return {
        async loadCalleeConfig() {
            try {
                const routeParams = getRouteParams();
                const config = await fetchCalleeConfig(routeParams);

                const data = {
                    ...routeParams,
                    ...config
                };

                dispatch({
                    type: CalleeActionTypes.LOADED,
                    data
                });
            } catch (error) {
                dispatch({
                    type: CalleeActionTypes.ERROR,
                    error
                });
            }
        },
        resetCalleeConfig() {
            dispatch({ type: CalleeActionTypes.RESET });
        },
        updateBanId(banId: string) {
            dispatch({ type: CalleeActionTypes.UPDATE_BAN_ID, banId });
        }
    }
}
