import {
    compose,
    mapProps,
    lifecycle,
    withReducer
}                               from 'recompose';
import createSocketDispatchers  from '../actions/SocketDispatchers';
import {
    socketReducer,
    defaultState,
}                               from '../reducers/SocketReducer';
import { SocketContainerProps } from '@portlet/types/SocketTypes';

export default compose(
    withReducer('socketState', 'socketDispatch', socketReducer, defaultState),
    mapProps((ownProps: SocketContainerProps) => ({
        ...ownProps,
        socketDispatchers: createSocketDispatchers(ownProps)
    })),
    lifecycle<SocketContainerProps, {}>({
        componentDidMount() {
            this.props.socketDispatchers.initializeCallSocket();
        },
        componentWillUnmount() {
            if (this.props.socketState.socket) {
                this.props.socketDispatchers.disconnectCallSocket();
            }
        }
    }),
);
