import { getToken }        from './SessionService';
import { FormResponse }    from '../types/FormTypes';
import { SharedFormData }  from '../types/SharedTypes';
import { UserSession }     from '../types/CallTypes';

const formCache = Object.create(null);

const formatSBSEndpoint = (url: string, separator: string, sbs?: boolean) => sbs ? `${url}${separator}sbs=${sbs}` : url;

export async function loadLogActivityForm(banId: string, session?: UserSession, sbs?: boolean, context?: string): Promise<FormResponse> {
    // For now, we dismiss the cache on loading due to missing contacts
    /*
    if (formCache[banId]) {
        return Promise.resolve(formCache[banId].fields);
    }
    */

    const token = await getToken();

    const baseURL = `${STFLO_ENV_VARS.WEBAPP_HOST}/api/accounts/log-activity/form?ban_id=${banId}&context=${context}`;

    const response = await fetch(formatSBSEndpoint(baseURL, '&', sbs), ({
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache'
    }) as RequestInit);

    // error handling
    if (!response.ok) {
        throw {
            message: [`Error ${response.status} - ${response.statusText}`]
        };
    }

    const form = await response.json();

    formCache[banId] = form;

    return form.fields;
}

export async function submitLogActivityForm(banId: string, data: SharedFormData, session?: UserSession, sbs?: boolean) {
    const { method, url } = formCache[banId];

    const token = await getToken();

    const response = await fetch(formatSBSEndpoint(url, '?', sbs), ({
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data)
    }) as RequestInit);

    // error handling
    if (!response.ok) {
        const payload = await response.json();

        const evt = new Event('LAACreateFailed');
        window.document.body.dispatchEvent(evt);

        throw {
            message: payload
        };
    }

    return response;
}
