import * as React from 'react';
import * as cx    from 'classnames';

import './LoadingAnimation.scss';

const LoadingAnimation = (props) => (
    <div className={cx('loader', props.className, {[`loader--${props.formKey}`] : props.formKey})}>
        <svg className="loader__circle" viewBox="25 25 50 50">
            <circle className="loader__path" cx="50" cy="50" r="20" fill="none" strokeWidth="1" strokeMiterlimit="10"/>
        </svg>
    </div>
);

export default LoadingAnimation;
