import { SocketState, SocketActionPayload, SocketActionTypes } from '@portlet/types/SocketTypes';
import { CallStatus } from '@portlet/types/CallTypes';

export const defaultState: SocketState = {
    callStatus: CallStatus.PENDING
};

export function socketReducer(state: SocketState, action: SocketActionPayload): SocketState {
    if (process.env.NODE_ENV === 'development') {
        console.group(`Socket action: ${SocketActionTypes[action.type]}`);
        console.log(action);
        console.groupEnd();
    }

    switch (action.type) {
        case SocketActionTypes.SOCKET_INITIALIZE:
            return {
                ...state,
                socket: action.socket
            };

        case SocketActionTypes.START_CALL:
            const { error, ...stateWithoutError } = state;

            return {
                ...stateWithoutError,
                callStatus: CallStatus.CONNECTING,
                callId: action.callId
            };

        case SocketActionTypes.NOT_AUTHORIZED:
            return {
                ...state,
                callStatus: CallStatus.NOT_AUTHORIZED,
                authUrl: action.authUrl
            };

        case SocketActionTypes.CALL_INITIATED:
            const nextState = (state.callStatus === CallStatus.CONNECTING) ? { ...state, callStatus: CallStatus.INITIATED, referenceId: action.referenceId } : state;
            return nextState;

        case SocketActionTypes.CALL_STARTED:
            const { authUrl, ...stateWithoutUrl } = state;

            return {
                ...stateWithoutUrl,
                callStatus: CallStatus.RINGING
            };

        case SocketActionTypes.ORIGIN_CONNECTED:
            return {
                ...state,
                callStatus: CallStatus.CONNECTED
            };

        case SocketActionTypes.CALL_ANSWERED:
            return {
                ...state,
                callStatus: CallStatus.ANSWERED
            };

        case SocketActionTypes.CALL_NOT_ANSWERED:
            return {
                ...state,
                callStatus: CallStatus.NOT_ANSWERED
            };

        case SocketActionTypes.SOCKET_ERROR:
            return {
                ...state,
                error: action.error
            };

        case SocketActionTypes.CALL_ERROR:
            // if the call has been answered, end it so the user can still log activity
            // if the call has not been answered, display the error to the user
            const isAnswered = (state.callStatus === CallStatus.ANSWERED);
            return {
                ...state,
                callStatus: isAnswered ? CallStatus.COMPLETED : CallStatus.ERROR,
                error: isAnswered ? null : action.error
            };

        case SocketActionTypes.CALL_ABORTED:
            return {
                ...state,
                callStatus: CallStatus.PENDING
            };

        case SocketActionTypes.END_CALL:
            // if the call was answered or is already completed, show the LAA form
            // if the call was never answered, reset back to PENDING
            const wasAnswered = (state.callStatus === CallStatus.ANSWERED || state.callStatus === CallStatus.COMPLETED);
            return {
                ...state,
                callStatus: wasAnswered ? CallStatus.COMPLETED : CallStatus.PENDING
            };

        case SocketActionTypes.RESET_STATUS:
            return {
                ...state,
                callStatus: CallStatus.PENDING
            };

        case SocketActionTypes.SOCKET_DISCONNECT:
            return {
                ...state,
                callStatus: CallStatus.DISCONNECTED
            };

        default:
            return state;
    }
}
