import {
    compose,
    withReducer,
    withProps,
    lifecycle
}                                  from 'recompose';
import {
    calleeReducer,
    defaultState
}                                  from '../reducers/CalleeReducer';
import { createCalleeDispatchers } from '../actions/CalleeDispatchers';
import { CalleeContainerProps }    from '@portlet/types/CalleeTypes';

export default compose(
    withReducer('calleeConfig', 'calleeDispatch', calleeReducer, defaultState),
    withProps((ownProps: CalleeContainerProps) => ({
        ...ownProps,
        calleeDispatchers: createCalleeDispatchers(ownProps)
    })),
    lifecycle({
        componentDidMount() {
            this.props.calleeDispatchers.loadCalleeConfig();
        },
    })
);
