import { UserSession } from '../src/types';
import segmentService  from './service';

interface SegmentGlobalScope extends Window {
    analytics: {
        [method: string]: any
    }
}

const global = window as unknown as SegmentGlobalScope;

export function startSegment(segmentKey: string) {
    /* tslint:disable */
    
    // Segment Runtime 3rd party code
    const analytics = global.analytics = global.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.'); else {
        analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']; analytics.factory = function (t) { return function () { let e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics; }; }; for (let t = 0; t < analytics.methods.length; t++) { let e = analytics.methods[t]; analytics[e] = analytics.factory(e); } analytics.load = function (t) { let e = document.createElement('script'); e.type = 'text/javascript'; e.async = !0; e.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'; let n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(e, n); }; analytics.SNIPPET_VERSION = '4.0.0';
        analytics.load(segmentKey);
    }
    /* tslint:enable */
}

export function trackMessage(message: string, payload: object) {
    global.analytics && global.analytics.track(message, payload);
}

export async function identifyUser(apiHost: string, user: Partial<UserSession>) {
    const data = await segmentService(apiHost, user);

    global.analytics.identify(data.segmentData.email, data.segmentData, {
        Intercom: data.intercomData
    });
}
