import { matchPath }       from 'react-router';
import Routes              from '../routes';
import { CallRouteParams } from '@portlet/types/CallTypes';

export const getRouteParams = (): CallRouteParams => {
    const match = matchPath(window.location.pathname, {
        path: Routes.CALL
    });

    return (match && match.params || {});
};

