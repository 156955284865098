import {
    SharedState,
    SharedActions,
    SharedFormProgress,
    SharedStateActionPayload,
}                   from '../types/SharedTypes';
import { FormKeys } from '../types/FormTypes';

export const sharedState: SharedState = {
    form: {
        [FormKeys.LOG_ACTIVITY]: {
            status: SharedFormProgress.ACTIVE,
        },
        [FormKeys.ADD_LEAD]: {
            status: SharedFormProgress.ACTIVE,
        },
        [FormKeys.ADD_CONTACT]: {
            status: SharedFormProgress.ACTIVE,
        }
    },
};

const sharedStateReducerActions = {
    [SharedActions.RESET_FORM](state: SharedState, action: SharedStateActionPayload): SharedState {
        return {
            ...state,
            form: {
                ...state.form,
                [action.formName]: {
                    status: SharedFormProgress.ACTIVE
                }
            }
        }
    },
    [SharedActions.CLEAR_FORMS](state: SharedState, action: SharedStateActionPayload): SharedState {
        return {
            form: {
                ...sharedState.form
            }
        }
    },
    [SharedActions.SAVING_FORM](state: SharedState, action: SharedStateActionPayload): SharedState {
        return {
            form: {
                ...state.form,
                [action.formName]: {
                    status: SharedFormProgress.SAVING
                }
            }
        }
    },
    [SharedActions.SAVED_FORM](state: SharedState, action: SharedStateActionPayload): SharedState {
        return {
            form: {
                ...state.form,
                [action.formName]: {
                    status: SharedFormProgress.SAVED
                }
            }
        };
    },
    [SharedActions.FORM_ERROR](state: SharedState, action: SharedStateActionPayload): SharedState {
        return {
            form: {
                ...state.form,
                [action.formName]: {
                    ...state.form[action.formName],
                    error: {
                        message: action.message
                    }
                }
            }
        };
    }
};

export function sharedStateReducer(state: SharedState, action: SharedStateActionPayload): SharedState {
    if (process.env.NODE_ENV === 'development') {
        console.group(`Shared State action: ${action.type}`);
        console.log(action);
        console.groupEnd();
    }

    return sharedStateReducerActions[action.type] ? sharedStateReducerActions[action.type](state, action) : state;
};


