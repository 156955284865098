import { getToken }       from './SessionService';
import {
    FormResponse,
    ContactFormResponse
}                         from '../types/FormTypes';
import { SharedFormData } from '../types/SharedTypes';
import { UserSession }    from '../types/CallTypes';

// no parameters so only need to fetch once
let addLeadFormCache = null;

export async function loadAddLeadForm(session?: UserSession): Promise<FormResponse> {
    if (addLeadFormCache && addLeadFormCache.fields) {
        return addLeadFormCache.fields;
    }

    const token = await getToken();

    const response = await fetch(`${STFLO_ENV_VARS.WEBAPP_HOST}/v1/api/unrecognized/add-lead/form`, ({
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache'
    }) as RequestInit);

    // error handling
    if (!response.ok) {
        throw {
            message: [`Error ${response.status} - ${response.statusText}`]
        };
    }

    const form = await response.json();

    addLeadFormCache = form;

    return form.fields;
}

export async function submitAddLeadForm(formData: SharedFormData): Promise<ContactFormResponse> {
    const { method, url } = addLeadFormCache;

    const token = await getToken();

    const response = await fetch(url, ({
        method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData)
    }));

    const payload = await response.json();

    // error handling
    if (!response.ok) {
        throw {
            message: payload
        };
    }

    return payload;
}

