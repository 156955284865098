// pollyfills
import 'core-js/modules/es6.string.starts-with';
import 'core-js/modules/es6.object.assign';
import 'core-js/modules/es7.object.values';
import 'es6-promise/auto';
import 'whatwg-fetch';

import * as React               from 'react';
import {
    Route,
    Switch
}                               from 'react-router';
import Routes                   from './routes';
import HeaderComponent          from './components/Header';
import AppContainer             from './containers/AppContainer';
import { AppProps }             from '@portlet/types/CallTypes';
import LoadingAnimation         from '@portlet/components/LoadingAnimation/LoadingAnimation';

import './components/Call/CallStyle.scss';

const Search = React.lazy(() => import(/* webpackChunkName: "SearchComponent" */ './components/Search'));
const Call = React.lazy(() => import(/* webpackChunkName: "CallComponent" */ './components/Call'));

const App = (props: AppProps) => (
    <div className="stfloCallWidget">
        <HeaderComponent
            callStatus={props.socketState.callStatus}
            translate={props.translate}
            formState={props.sharedState.form.logActivity.status}
        />
        <div className="stfloCallWidget__bodyContainer">
            <React.Suspense fallback={<LoadingAnimation />}>
                {/* TODO: leaving this in for now because product says sidebar will 100% be a part of future designs */}
                {/* however if we don't end up including the sidebar later, we could move a lot of data down from app level */}
                {/* {props.calleeConfig.to && <SidebarComponent contact={props.calleeConfig.name} callStatus={props.socketState.callStatus} phoneNumber={props.calleeConfig.to} translate={props.translate} />} */}
                <Switch>
                    <Route path={Routes.SEARCH} render={routerProps => <Search {...routerProps} {...props} />} exact />
                    {/* nb: need banId as a separate prop in component rendered by the call route because it controls the form flow for unrecognized calling */}
                    <Route path={Routes.CALL} render={routerProps => <Call {...routerProps} {...props} />} />
                </Switch>
            </React.Suspense>
        </div>
    </div>
);

export default AppContainer(App);

