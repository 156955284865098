import * as PropTypes from 'prop-types';
import { ActionPayload } from '.'
import { FormKey }       from './FormTypes';

export enum SharedFormProgress {
    ACTIVE,
    SAVING,
    SAVED
};

interface SharedFormError {
    message: string[];
}

export enum SharedActions {
    RESET_FORM  = 'reset',
    CLEAR_FORMS = 'clear',
    SAVE_FORM   = 'save',
    SAVING_FORM = 'saving',
    SAVED_FORM  = 'saved',
    FORM_ERROR  = 'error'
}

export interface SharedFormSaveConfig {
    formName: FormKey;
    element: Element;
    additionalFields?: AdditionalFields;
    callback?: (params?: any) => void;
    sbs?: boolean;
}
export interface SharedStateDispatchers {
    resetForm?: (formName: FormKey) => void;
    clearForms?: () => void;
    saveForm?: (config: SharedFormSaveConfig) => void;
}

export type SharedFormProgressType =
    | SharedFormProgress.ACTIVE
    | SharedFormProgress.SAVING
    | SharedFormProgress.SAVED;

export type SharedStateActions =
    | SharedActions.RESET_FORM
    | SharedActions.SAVE_FORM
    | SharedActions.SAVING_FORM
    | SharedActions.FORM_ERROR;

export type SharedStateActionPayload = ActionPayload<SharedStateActions>;

export interface AdditionalFields {
    [fieldKey: string]: string | number;
}

export interface SharedState {
    form: {
        [formName: string]: {
            status?: SharedFormProgressType,
            error?: SharedFormError,
        }
    }
}

export interface SharedStateContext {
    sharedState?: SharedState;
    sharedStateDispatchers?: SharedStateDispatchers
}

export const SharedStateContextTypes = {
    sharedState: PropTypes.object.isRequired,
    sharedStateDispatchers: PropTypes.shape({
        resetForm: PropTypes.func,
        clearForms: PropTypes.func,
        saveForm: PropTypes.func
    })
}

export interface SharedFormData {
    [formField: string]: string;
}
