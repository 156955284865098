import { ActionPayload } from './';
import { SharedStateContext } from './SharedTypes';
import { UserSession, DefaultFormPayload } from './CallTypes';

export enum FormCollectionTypes {
    EAGER = 'eager',
    LAZY = 'lazy'
}

export enum FormActionTypes {
    LOADED,
    ERROR,
    UPDATE,
    SAVE,
    LAZY_LOAD
}

type FormActionType =
    | FormActionTypes.LOADED
    | FormActionTypes.ERROR
    | FormActionTypes.UPDATE
    | FormActionTypes.SAVE
    | FormActionTypes.LAZY_LOAD;

export type FormActionPayload = ActionPayload<FormActionType>;

export enum FormFieldTypes {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    SELECT = 'select',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    DATEPICKER = 'datePicker',
    INPUTGROUP = 'inputGroup',
    EMAIL = 'email',
    PASSWORD = 'password',
    SECTION = 'section',
    GROUP = 'groupedSection',
    GROUPED_CHECKBOX = 'groupedCheckbox'
}

export type FormFieldValue = string;

export enum FormContexts {
    CALL = 'call',
    CHAT = 'chat',
    WEBAPP = 'webapp',
    WIDGET = 'widget',
    GLOBAL_CALLING = 'globalCalling'
}

export type FormContext = FormContexts.CALL | FormContexts.CHAT | FormContexts.WEBAPP | FormContexts.WIDGET | FormContexts.GLOBAL_CALLING;

interface FormFieldCollection {
    items: FormFieldSelectItems;
    template: null;
    type: string;
}

export interface FormFieldItem {
    enabled?: boolean;
    selected?: boolean;
    label: string;
    name: FormFieldValue; // 'value' comes in as 'name' from the form API
    result?: {
        name: string;
        fields: FormFieldset;
    };
}
export interface FormFieldGroup {
    name: string;
    label: string;
    enabled: boolean;
    required?: boolean;
    placeholder?: string;
    multiple?: boolean;
    type: FormFieldTypes;
    collection?: FormFieldCollection;
    defaultValue?: string;
    toolTip?: string;
    info?: string;
    element_list?: FormFieldGroup[];
    selected?: boolean;
}

interface FormInputGroup {
    fields: InputGroupFieldset;
    label: string;
    type: FormFieldTypes.INPUTGROUP;
}

interface InputGroupFieldset {
    [key: number]: InputGroupField;
}

interface InputGroupField {
    name: string;
    label: string;
    enabled: boolean;
    type: string;
    required: boolean;
    isNumeric: boolean;
}

interface CommonFormElement {
    name: string;
    fieldPath: string;
    fieldPathObj: string[];
    props: FormProps;
}

export interface FormFieldElement extends CommonFormElement {
    fieldConfig: FormFieldGroup;
}

export interface InputGroupElement extends CommonFormElement {
    fieldConfig: FormInputGroup;
}

export type FormFieldSelectItems = FormFieldItem[];

export interface FormFieldset {
    [fieldName: string]: FormFieldGroup;
}

export interface FormResponse {
    url: string;
    method: string;
    fields: FormFieldset;
}

export interface ContactFormResponse {
    banId: string;
    message: string;
    error?: {
        message: string;
    }
}

export type FormActiveFields = string[];

export enum FormKeys {
    NOTES = 'notes',
    ADD_LEAD = 'addLead',
    ADD_CONTACT = 'addContact',
    LOG_ACTIVITY = 'logActivity',
    CREATE_USER = 'createUser'
}

export type FormKey = FormKeys.ADD_LEAD | FormKeys.LOG_ACTIVITY | FormKeys.NOTES | FormKeys.ADD_CONTACT;

export interface FormState {
    activeFields: FormActiveFields;
    valid: boolean;
    saving: boolean;
    formVals?: { [key: string]: string };
    formFields?: FormFieldset;
    error?: { message: string[] };
    formSubmitURL?: string;
    formSubmitMethod?: string;
}

export type FormEventHandler = (event: React.SyntheticEvent<any>) => void;

export interface FormProps extends SharedStateContext, PortletMainProps {
    formKey: FormKey;
    formContext: FormContext;
    banId?: string;
    formDispatch: (action: FormActionPayload) => void;
    form: FormState;
    onChangeValue: FormEventHandler;
    onChangeText?: FormEventHandler;
    onSubmit: FormEventHandler;
    onCloseModal: () => void;
    session?: UserSession;
    defaultValues?: DefaultFormPayload;
    updateDefaultFormState?: (payload: DefaultFormPayload) => void;
    translate: TranslationFunction;
    sbs?: boolean;
}

export interface FormFieldConfig {
    name: string;
    fieldPath: string;
    fieldPathObj: string[];
    fieldConfig: FormFieldGroup;
    props: FormProps;
}
