import { CalleeConfig, EmptyFunc } from './CallTypes';
import { ActionPayload } from '.';

export interface CalleeContainerProps {
    calleeConfig?: CalleeConfig;
    calleeDispatch?: (action: CalleeActionPayload) => void;
    loadCalleeConfig?: EmptyFunc;
    calleeDispatchers?: CalleeDispatchers;
}

export interface CalleeDispatchers {
    loadCalleeConfig: () => void;
    resetCalleeConfig: EmptyFunc;
    updateBanId: (banId: string) => void;
}

export enum CalleeActionTypes {
    LOADED,
    ERROR,
    RESET,
    UPDATE_BAN_ID
}

export type CalleeActionPayload = ActionPayload<CalleeActionTypes>;
