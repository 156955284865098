// polyfills
import 'core-js/modules/es7.object.values';
import 'core-js/modules/es6.string.includes';

import {
    SharedActions,
    SharedFormData,
    SharedStateDispatchers,
    SharedFormSaveConfig
}                                from '../types/SharedTypes';
import { submitLogActivityForm } from '../services/LogActivityService';
import { submitAddLeadForm }     from '../services/AddLeadService';
import { submitAddContactForm }  from '../services/AddContactService';
import { serializeForm }         from '../helpers/forms';
import { FormKeys, FormKey }     from '../types/FormTypes';

const global = (window as any);

const FormSubmitFunctions = {
    async [FormKeys.LOG_ACTIVITY](formData: SharedFormData, sbs: boolean) {
        await submitLogActivityForm(formData.banId, formData, null, sbs);
        return;
    },
    async [FormKeys.ADD_LEAD](formData: SharedFormData) {
        const { banId } = await submitAddLeadForm(formData);
        return banId;
    },
    async [FormKeys.ADD_CONTACT](formData: SharedFormData) {
        const { banId } = await submitAddContactForm(formData);
        return banId;
    }
}

export const createSharedStateDispatchers = (ownProps): SharedStateDispatchers => {
    const { sharedStateDispatch: dispatch } = ownProps;

    return {
        resetForm(formName: FormKey) {
            dispatch({ type: SharedActions.RESET_FORM, formName })
        },
        clearForms() {
            dispatch({ type: SharedActions.CLEAR_FORMS })
        },
        async saveForm({ formName, element, additionalFields, callback, sbs }: SharedFormSaveConfig) {
            dispatch({ type: SharedActions.SAVING_FORM, formName });

            const formData = serializeForm(element, additionalFields);

            try {
                const response = await FormSubmitFunctions[formName](formData, sbs);

                if (formName === FormKeys.ADD_LEAD || formName === FormKeys.ADD_CONTACT) {
                    callback(response) // need to update banId to change to log activity form
                } else if (callback) {
                    callback();
                }

                dispatch({ type: SharedActions.SAVED_FORM, formName });

                // Refresh the timeline if the user is in the CRM details page in webapp
                if (global.webapp && window.location.href.includes('accounts') && window.location.href.includes('client')) {
                    if (global.webapp && window.location.href.includes('accounts') && window.location.href.includes('client')) {
                        global.webapp.dispatch('timeline-reload');
                        global.webapp.dispatch('timeline-reload');
                    }
                }

                // Dispatch SBS specific activity to indicate that webapp should load opps form
                // Store Ban ID and form all form data in localstorage so webapp can check for specific hardcoded form values :(
                if (global.webapp && sbs) {
                    localStorage.setItem('sbsActivityBan', (additionalFields.banId as string));
                    localStorage.setItem('sbsOutcomeData', JSON.stringify(formData));
                    global.webapp.dispatch('sbs-activity-logged');
                }
            } catch (error) { // always expecting error as error { message: Array<string> }
                const message = Object.values(error.message);

                dispatch({ type: SharedActions.RESET_FORM, formName })
                dispatch({ type: SharedActions.FORM_ERROR, message, formName });
            }
        }
    }
}
