import {
    compose,
    mapProps,
    lifecycle,
    withReducer
}                                       from 'recompose';
import { createTranslationDispatchers } from './TranslationDispatchers';
import {
    translationsReducer,
    defaultState,
}                                       from './TranslationsReducer';
import {
    translate,
    translationsCache
}                                       from '../services/TranslationService';
import { TranslationsContainerProps }   from '../types/TranslationTypes';

export default compose(
    withReducer('translations', 'translationsDispatch', translationsReducer, defaultState),
    mapProps((ownProps: TranslationsContainerProps) => ({
        ...ownProps,
        translate,
        translationsDispatchers: createTranslationDispatchers(ownProps)
        // memoize language, etc.
    })),
    lifecycle <TranslationsContainerProps, {}>({
        componentDidMount() {
            if (!translationsCache && this.props.userLanguage) {
                this.props.translationsDispatchers.loadTranslations(this.props.userLanguage);
            }
        },
        componentDidUpdate(prevProps: TranslationsContainerProps) {
            if (!translationsCache && this.props.userLanguage && this.props.userLanguage !== prevProps.userLanguage) {
                this.props.translationsDispatchers.loadTranslations(this.props.userLanguage);
            }
        },
    })
);
