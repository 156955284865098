import {
    compose,
    lifecycle,
    withReducer,
    mapProps
}                                       from 'recompose';
import { createSharedStateDispatchers } from './actions';
import {
    sharedState,
    sharedStateReducer
}                                       from './reducer';

export const connectSharedState = compose(
    withReducer('sharedState', 'sharedStateDispatch', sharedStateReducer, sharedState),
    mapProps((ownProps) => ({
        ...ownProps,
        sharedStateDispatchers: createSharedStateDispatchers(ownProps)
    })),
    lifecycle({
        componentWillUnmount() {
            // prevent error states from persisting across renders
            this.props.sharedStateDispatchers.clearForms();
        }
    })
);

