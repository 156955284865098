import { ActionPayload } from '.';
import { ErrorPayload }  from './CallTypes';

export interface TranslationsContainerProps {
    userLanguage: string;
    translate: TranslationFunction;
    translations?: TranslationsState;
    translationsDispatch?: (action: TranslationsActionPayload) => void;
    translationsDispatchers?: TranslationsDispatchers;
}

export interface TranslationsState {
    loaded: boolean;
    error?: ErrorPayload;
}

export enum TranslationsActionTypes {
    LOADED,
    ERROR
}

export interface TranslationsDispatchers {
    loadTranslations: (language: string) => void;
}

export type TranslationsActionPayload = ActionPayload<TranslationsActionTypes>;
