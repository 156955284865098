import { ActionPayload } from '.';
import { EmptyFunc, CallStatusType, ErrorPayload } from './CallTypes';

export interface SocketDispatchers {
    initializeCallSocket?: EmptyFunc;
    connectCallSocket?: EmptyFunc;
    disconnectCallSocket?: EmptyFunc;
    onCallSocketUpdate?: (payload: SocketResponseBody) => void;
    startCall: EmptyFunc;
    endCall: EmptyFunc;
    resetCall: EmptyFunc;
}

export enum SocketExchanges {
    CALL_INITIATE = '/app/dispatch/conversation.call.initiate.v1',
    CALL_ABORT = '/app/dispatch/conversation.call.abort.v1',
    CALL_NOTIFICATION = '/user/exchange/statflo.rpc/v1.notification',
    CALL_REPLY = '/user/exchange/statflo.rpc/v1.reply', // not currently being used
    CALL_ERROR = '/user/exchange/statflo.rpc/v1.error' // not currently being used
}

export enum SocketActionTypes {
    SOCKET_INITIALIZE,
    SOCKET_ERROR,
    START_CALL,
    CALL_INITIATED,
    CALL_STARTED,
    ORIGIN_CONNECTED,
    CALL_ANSWERED,
    CALL_NOT_ANSWERED,
    CALL_ERROR,
    END_CALL,
    RESET_STATUS,
    SOCKET_DISCONNECT,
    NOT_AUTHORIZED,
    CALL_ABORTED
}

export type SocketActionPayload = ActionPayload<SocketActionTypes>;

export interface SocketResponseBody {
    details: SocketResponseBodyDetails;
    event: string; // call.event
    sessionId: string;
    userId: string;
}

interface SocketResponseBodyDetails {
    call: SocketCall;
    created_at: string;
    event_date: string;
    id: string;
    message: string;
    metadata: SocketResponseMetadata;
    status: SocketStatusType;
    updated_at: string;
    display_status: SocketStatusType;
}

export enum SocketStatuses {
    INITIATE = 'INITIATE',
    INITIATED = 'INITIATED',
    IN_PROGRESS = 'IN_PROGRESS', // nb: this is actually only a display status - no way to extend enums for now :(
    ABORT = 'ABORT',
    ABORTED = 'ABORTED',
    ORIGIN_RINGING = 'ORIGIN_RINGING',
    DESTINATION_RINGING = 'DESTINATION_RINGING',
    ORIGIN_CONNECTED = 'ORIGIN_CONNECTED',
    DESTINATION_CONNECTED = 'DESTINATION_CONNECTED',
    ORIGIN_FINISHED = 'ORIGIN_FINISHED',
    DESTINATION_FINISHED = 'DESTINATION_FINISHED',
    ORIGIN_NOT_ANSWERED = 'ORIGIN_NOT_ANSWERED',
    DESTINATION_NOT_ANSWERED = 'DESTINATION_NOT_ANSWERED',
    ORIGIN_NOT_AUTHORIZED = 'ORIGIN_NOT_AUTHORIZED',
    DESTINATION_NOT_AUTHORIZED = 'DESTINATION_NOT_AUTHORIZED',
    FAILED = 'FAILED'
}

type SocketStatusType = SocketStatuses;

interface SocketCall {
    created_at: string;
    destination_phone_number: string;
    direction: SocketCallDirectionsType;
    id: string;
    metadata: {
        session_id: string;
        business_unit_id: string;
    };
    organization_id: string;
    origin_phone_number: string;
    relay_phone_number: string;
    updated_at: string;
    user_id: string;
}

export enum SocketCallDirections {
    OUTBOUND = 'OUTBOUND',
    INBOUND = 'INBOUND'
}

type SocketCallDirectionsType = SocketCallDirections;

interface SocketResponseMetadata {
    business_unit_id?: string;
    division_id?: any;
    organization_id: string;
    authorization_url?: string;
    session_id: string;
    user_id: string;
    reference_id: string;
}

export interface SocketContainerProps {
    socketState?: SocketState;
    socketDispatch: (action: SocketActionPayload) => void;
    socketDispatchers: SocketDispatchers;
}

export interface SocketState {
    socket?: any; // FIXME: didn't want to add @statflo/socket as dep to portlets
    callStatus: CallStatusType;
    error?: ErrorPayload;
    referenceId?: string;
    callId?: string;
    authUrl?: string;
}
