import 'core-js/modules/es6.string.includes';

import { AdditionalFields, SharedFormData } from '../types/SharedTypes';

// can expand this as we support more form fields
enum FormFieldTypes {
    SELECT = 'select-one',
    MULTI_SELECT = 'select-multiple',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    TEXT = 'text',
    EMAIL = 'email',
    PASSWORD = 'password',
    GROUPED_CHECKBOX = 'grouped-checkbox'
}

const formFieldTest = new RegExp(/items|dependent_field|collection|\d/gi);

// if element don't support name, use id instead.
const formatFieldName = (element: HTMLFormElement) => {
    if (element.name) {
        return !element.name.includes('custom_field') && element.name.split('.').filter(item => !item.match(formFieldTest)).join('.');
    } else {
        return !element.id.includes('custom_field') && element.id.split('.').filter(item => !item.match(formFieldTest)).join('.');
    }
};

// extra functionality for handling cases where we use multi-selects
const retrieveMultiSelectData = (element: HTMLFormElement) => [].map.call(element.selectedOptions, (item) => item.value);

const retrieveGroupedCheckboxData = (element: HTMLFormElement) => {

    const selected: string[] = [];

    [].map.call(element.children, (child) => {

        // we are expecting only one element. (li -> label -> input)
        const inputCheckbox =  child.querySelectorAll('input.FormItem__GroupedCheckboxListItem__Input');
        // in case we didn't find any fast return else if checked, add to selected.
        if (!inputCheckbox || !inputCheckbox[0]) return;
        if (!inputCheckbox[0].disabled && inputCheckbox[0].checked) selected.push(inputCheckbox[0].id);
    });

    return selected;
};

const getValue = (element: HTMLFormElement, values: SharedFormData, name: string) => {
    if (values[name]) {
        return values[name]; // avoid overwriting values
    }

    if (element.type === FormFieldTypes.MULTI_SELECT) {
        return retrieveMultiSelectData(element);
    }

    // we are using custom-type attribute for elements that don't support type attribute.
    if (!element.type && element.getAttribute('custom-type') === FormFieldTypes.GROUPED_CHECKBOX) {
        return retrieveGroupedCheckboxData(element);
    }

    // For radio buttons, need to only get values for checked options
    // if a radio input was checked, we associate the value with that input's name
    // if the next input with that name is unchecked, we return the previously retrieved value
    if (element.type === FormFieldTypes.RADIO && !element.checked) {
        return values[name] || null;
    }

    return element.value;
};

const reduceFormElements = (values: SharedFormData, element: HTMLFormElement) => {
    // if element name is not supported, use id instead.
    const name = formatFieldName(element) || element.name || element.id;

    if (!element.disabled) {
        values[name] = getValue(element, values, name) || null;
    }

    return values;
};

export const serializeForm = (form: Element, additionalFields?: AdditionalFields): SharedFormData => {
    const elements =  form.querySelectorAll('input:not(.FormItem__GroupedCheckboxListItem__Input), select, .textarea, ul.FormItem__GroupedCheckboxList');

    const formVals = [].reduce.call(elements, reduceFormElements, {});

    // append additional data that is required but not in the form
    if (additionalFields) {
        Object.keys(additionalFields).forEach(key => formVals[key] = additionalFields[key]);
    }

    return formVals;
};

export const clearForms = (formClass: string) => {
    const activeForms = document.querySelectorAll(formClass);

    if (activeForms) {
        [].forEach.call(activeForms, (form) => form.reset());
    }
};
